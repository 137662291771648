import React, { useEffect } from "react";
import styled from "styled-components";
import Menu from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import Loader from "./../../assets/loading.gif";
import "../../main.css";
import { Link, navigate } from "gatsby";

export default function Loading() {
    useEffect(() => {
        setTimeout(navigate('/document'), 1000);
    }, []);
    return (
        <>
        <Menu page={'document'} />
        <Wrapper>
                <LoadingContainer>
                <img className="loader" src={Loader} />
                </LoadingContainer>
        </Wrapper>
        <Footer />
        </>
    );
}

const LoadingContainer = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  .loader {
    -webkit-user-select: none;
    margin: auto;
  }
`
const Wrapper = styled.div`
  min-height: 95vh;
  width: 100%;
  background: #f7f9fa;
  position: relative;

  .chat {
    position: absolute;
    bottom: 10px;
    right: 30px;

    @media (max-width: 600px) {
      position: unset;
      display: flex;
      justify-content: end;
      margin-right: 20px;
      margin-bottom: 15px;
    }

    img {
      width: 40px;
      height: auto;
      margin: 10px 0;
    }
  }
  .custom-padding-left-doc {
    padding-left: 10px !important;
  }

  .custom-padding {
    padding: 0 121px 130px 121px;

    @media (max-width: 1024px) {
      padding: 0 50px 100px 50px;
    }

    @media (max-width: 768px) {
      padding: 0 20px;
    }
  }

  .wrap {
    position: relative;
    margin: auto;
    width: 100%;
    min-width: 320px;
    box-sizing: border-box;

    @media (max-width: 600px) {
      margin-top: 3rem;
    }

    .amp_fm_loading {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1000;
      background: rgba(255,255,255,0.8);
      text-align: center;
    }

    .align_middle {
      display: inline-block;
      vertical-align: middle;
      height: 100%;
      width: 0;
      margin-right: -.3em !important;
    }
  }
  .w-15 {
    width: 15% !important;
    padding-left: 20px !important;
  }
  .w-20 {
    width: 20% !important;
  }
  .w-25 {
    width: 25% !important;
    
    @media (max-width: 768px) {
      padding-inline: 20px !important;
    }
  }
  .w-35 {
    width: 35% !important;

    @media (max-width: 768px) {
      padding-inline: 20px !important;
    }
  }
  .w-40 {
    width: 40% !important;
  }

  @media (min-width: 480px) {
    .wrap {
      max-width: 768px;
      padding: 2em 1em;
    }
  }
  @media (min-width: 768px) {
    .wrap {
      max-width: 1024px;
      padding: 2em 0;
    }
  }
  @media (min-width: 1024px) {
    .wrap {
      max-width: 1280px;
      padding: 57px 0px 0 0;
    }
  }
  @media (min-width: 1280px) {
    .xs-hidden,
    .sm-hidden,
    .md-hidden,
    .lg-hidden,
    .xl-hidden,
    .xl-hide {
      display: none !important;
    }
    .wrap {
      max-width: 1280px;
      padding: 57px 0px 0 0;
    }
  }

  .MuiIconButton-root:hover {
    background-color: transparent !important;
  }
  thead {
    height: 2.78%;
    width: 80.63%;
    border-radius: 6px 6px 0 0 !important;
    background-color: #d6e1e5;
    tr {
      height: 41px;
      background: #d6e1e5;
      border-radius: 6px 6px 0 0 !important;
      padding: 0 0 0 20px;
      th {
        height: 41px;
        width: 6.53%;
        color: #212e33;
        font-family: Rajdhani;
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 22px;
        text-align: left;
      }
    }
  }
  .estimate {
    height: 30px;
    width: 30px;
    border-radius: 5px;
    margin: 0 8px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f7941d;
  }
  .workorder {
    height: 30px;
    width: 30px;
    border-radius: 5px;
    margin: 0 8px 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #9ac8f1;
  }
  .table_icons {
    display: flex;
    align-items: center;
    justify-content: center;

    .table_icons_btn {
      height: 40px;
      width: 40px;
      background: #414141;
      border: none;
      outline: none;
      border-radius: 5px;
      margin: 0 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  tbody {
    background: #fff;
    tr {
      td {
        height: 5.15%;
        width: 3.89%;
        color: #212e33;
        font-family: Roboto;
        font-weight: 400;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 18px;
        padding: 20px 0 !important;
        text-align: left;
      }
    }
  }
`
const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px;

  .ToggleIcon-root-1 {
    width: 18px;
    height: 18px;
    display: inline-block;
    position: relative;
    color: #212e33;
  }

  .MuiIconButton-root:hover {
    background-color: transparent !important;
  }

  .color-black {
    color: #212e33;
  }
  .bottom-adnanced {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
    margin: 10px 0;

    p {
      font-size: 16px;
      color: #212e33;
      font-family: Rajdhani;
      text-transform: uppercase;
      font-weight: 600;
    }
  }
  .filter-btn {
    width: 135px;
    height: 44px;
    margin-left: 15px;
    border-radius: 4px;
    padding: 7px;
    background-color: #f7941c;
    text-align: center;
    font-size: 16px;
    text-transform: uppercase;
    color: #212e33;
    font-family: Rajdhani;
    border: none;
    outline: none;
    font-weight: 600;
    cursor: pointer;
  }
  .search {
    width: 100%;
    margin-bottom: 20px;

    @media (max-width: 600px) {
      margin-bottom: 30px;
    }
    .label {
      font-size: 14px;
      color: #212e33;
      font-weight: 400;
      margin-bottom: 8px;
      font-family: Roboto;
    }
    .alpha {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media (max-width: 968px) {
        flex-direction: column;
        align-items: flex-start;
      }
      .alpha-child {
        @media (max-width: 600px) {
          width: 100%;
        }
      }
      .label {
        margin-bottom: 10px !important;
        font-family: Roboto;
        font-weight: 500;
        color: #212e33;
        padding: 0;
      }

      .alpha-btn {
        height: 44px;
        width: 168px;
        background: #f7941c;
        border-radius: 3px;
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-family: Rajdhani;
        color: #212e33;
        outline: none;
        border: none;
        font-weight: 600;
        text-transform: uppercase;
      }
    }

    .search-button-block {
      @media (max-width: 600px) {
        display: flex;
        flex-direction: row-reverse;
        justify-content: end;
        align-items: center;
        margin-top: 5px;
      }
    }
    .search-input {
      height: 44px;
      width: 40%;
      outline: none;
      padding: 10px;
      color: #212e33;
      font-style: normal;
      border-radius: 4px;
      border: 1px solid #b4bebf;
      @media (max-width: 600px) {
        width: 100%;
        position: relative;
      }
      font-style: italic;
    }
    .search-input-spacing {
      padding-left: 35px;
      font-style: normal;
    }

    .search-block {
      @media (max-width: 600px) {
        flex-direction: column;
      }
    }
    .ad_opt {
      position: absolute;
      display: flex;
      align-items: center;
      height: 100%;

      z-index: 99;
      right: 85px;
      top: 0;
      @media (max-width: 600px) {
        position: unset;
        margin: 0 10px 0 0 !important;
        background-color: transparent;
      }
    }
    .paragraph-styling {
      font-size: 14px;
      margin: 0;
    }
    .clearIconStyling {
      position: absolute;
      z-index: 1;
      top: 14px;
      left: 13px;
    }
    .alpha-input {
      width: 526px !important;
      position: relative;

      @media (max-width: 1024px) {
        width: 657px !important;
      }

      @media (max-width: 767px) {
        width: 100% !important;
      }
    }
    .search-btn {
      height: 44px;
      width: 44px;
      background: #d6e1e5;
      margin: 0 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      cursor: pointer;

      @media (max-width: 600px) {
        margin: 0px;
      }
    }
    .orange-btn {
      background: #f7941c;
    }
  }

  .reset {
    position: absolute;
    top: 3%;
    right: 5%;
    font-weight: 600;
    img {
      width: 25px;
      height: auto;
      cursor: pointer;
    }
  }

  .toggleDiv {
    min-height: 100px;
    width: 526px;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: flex-start;
    flex-direction: column;
    font-size: small;
    color: #333;
    position: absolute;
    background: #ffffff;
    z-index: 999;
    left: 0%;
    box-shadow: 0 0 10px rgb(0 0 0 / 20%);

    @media (max-width: 768px) {
      left: 2%;
    }

    @media (max-width: 600px) {
      width: 309px;
      top: 70%;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    input[type="number"] {
      -moz-appearance: textfield; /* Firefox */
    }

    .custom-control-input:checked ~ .custom-control-label::before {
      border-color: #f7941c;
      background-color: #f7941c;
    }

    .inner-div {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      font-weight: normal;
      font-family: Roboto;

      @media (max-width: 600px) {
        flex-direction: column;
        align-items: flex-start;
      }

      .text {
        font-size: 14px;
        color: #212e33;
        font-weight: 500;
        font-family: Roboto;
      }
      p {
        margin-bottom: 0 !important;
      }
      .f-opt {
        margin-top: -5px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: Roboto;
        .checkbox-lg .custom-control-label::before,
        .checkbox-lg .custom-control-label::after {
          top: 0.8rem;
          width: 1.55rem;
          height: 1.55rem;
          font-weight: normal;
          font-size: 14px;
          color: #212e33;
          font-weight: 500;
        }
        .checkbox-lg .custom-control-label {
          padding-top: 6px;
          padding-left: 6px;
          font-weight: normal;
          font-size: 14px;
          color: #212e33;
          font-weight: 500;
        }
      }
      .inputs {
        width: 75%;

        @media (max-width: 600px) {
          width: 100%;
        }
      }
      .inputs-double {
        width: 100% !important;
      }
    }
  }
`
